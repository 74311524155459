import React from "react";
import { Container, Row, Col } from "reactstrap";
import Layout from "../components/layout";
import logo from "../images/NGO_Face_White_1000px.png";
import questions from "./questions.json";

const Comingsoon = () => {
  let randomQuestion = questions[Math.floor(Math.random() * questions.length)];

  return (
    <>
      <Container>
        <div id="wrapper">
          <div id="left">
            <img class="logo" src={logo} />
          </div>
          <div id="right">
            <p class="comingsoon">Coming soon.</p>
            <p class="bient364tdisponible">Bientôt disponible.</p>
            <p class="jp-text">近日公開</p>
            <p class="u0441u043au043eu0440u043eu043du0430u044du043au0440u0430u043du0430u0445">
              скоро на экранах.
            </p>
          </div>

          <div id="quote">
          <a href="mailto:info@nativegrapeodyssey.com"> {randomQuestion}</a>

          </div>
        </div>
      </Container>
    </>
  );
};
export default Comingsoon;
