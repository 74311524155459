import React from "react";
import { Container, Row, Col } from "reactstrap";
import Layout from "../components/layout";
import Logosmall from "../images/NGO_Logo_Black_500px.png";
import LogoEU from "../images/enjoy-signature-bottom_en.jpg";

const Footer = () => (
  <div id="footer">
    <div className="top-left">
      <img id="LogoNGO" src={Logosmall} />{" "}
    </div>
    <div id="emailID" className="center">
      <h6>info@nativegrapeodyssey.com</h6>
    </div>
    <div className="top-right">
      <img id="LogoEU" src={LogoEU} />{" "}
    </div>
  </div>
);

export default Footer;
