import React from "react";
import {
  Container,
  Card,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle
} from "reactstrap";
import Link from "gatsby-link";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import ComingSoon from "../components/coming-soon";
import Footer from "../components/footer";
import "./custom-styles.css";

const IndexPage = ({ data }) => {
  const posts = data.allMarkdownRemark.edges.filter(
    post =>
      !post.node.frontmatter.hidden &&
      post.node.frontmatter.contentType === "blog"
  );
  return (
    <div>
      <Container>
        <ComingSoon />

        <Footer />
      </Container>
    </div>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          frontmatter {
            title
            contentType
            date(formatString: "MMMM DD, YYYY")
            path
            hidden
          }
        }
      }
    }
  }
`;
